<template>
    <section class="calendario-ver-detalle container-fluid">
        <navbar-admin icon="icon-calendar-multiselect" />
        <!-- info top new -->
        <info-top :id="1" 
        :img="cabecera.imagen" 
        :equipo="cabecera.nombre" 
        :etapa="cabecera.etapas"
        :planMantenimiento="cabecera.plan_mantenimiento"
        :configuracion="cabecera.configuraciones"
        :material="cabecera.materiales" />
        
        <div class="row listado-por-dia my-4">
            <div class="col-md-2 col-lg-1 border-right">
                <div class="bg-f5 br-4 ">
                    <div class="bg-general f-12 text-white border-general br-t-4 text-center">Enero</div>
                    <div class="f-17 f-500 d-middle-center border-bottom border-right border-left">
                        Días
                    </div>
                </div>
                <div class="text-center my-2 py-1 cr-pointer month-day-selected" @click="verEtapasDia">
                    20
                </div>
                <div class="text-center my-2 py-1 cr-pointer month-day" @click="verEtapasDia">
                    15
                </div>
                <div class="text-center my-2 py-1 cr-pointer month-day" @click="verEtapasDia">
                    10
                </div>
            </div>
            <div class="col-11 ">
                <div class="row pb-2 border-bottom-g">
                    <div class="col my-auto">
                        <p class="f-17 text-general f-600">Etapas</p>
                    </div>
                    <div class="col-auto my-auto">
                        <el-input v-model="searchStage" size="small" placeholder="Buscar etapa" />
                    </div>
                    <!-- filtro -->
                    <div class="col-auto icon-option my-auto pl-0 my-auto">
                        <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                            <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltrosEquipo">
                                <i class="icon-filter text-white" />
                            </button>
                        </el-tooltip>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-11 calendario-ver-detalle-etapas">
                        <el-collapse v-model="activeName" accordion>
                            <el-collapse-item v-for="(data, idx) in 3" :key="idx" :name="idx">
                                <template slot="title">
                                    <div class="d-middle">
                                        <p class="text-5d f-500">Efecto  {{ idx+1 }} </p>
                                    </div>
                                </template>
                                <div class="row justify-content-center">
                                    <div v-for="(data,i) in 3" :key="i" class="col-9 bg-general br-5 text-white f-14 f-500 shadow29 cr-pointer mb-2" @click="verDetalleConfiguracion(i)">
                                        <p class="pl-3 p-tb-4px">Configuración {{i+1}}</p>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </div>
        </div>
        
        <modal-filtro-lateral ref="modalFiltroEquipos" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import infoTop from './components/infoTop.vue'
import modalFiltroLateral from './partials/modalFiltroLateral.vue'
export default {
    components:{
        infoTop,
        modalFiltroLateral,
    },
    data() {
        return {
            searchStage: '',
            activeName: '1',
            infoTop:{
                    id: 1,
                    img: '/img/pruebas/equipo1.png',
                    equipo: 'Reactor de leche fluidizado',
                    etapa: 'Efecto 1',
                    planMantenimiento: 'Plan de mantenimiento',
                    configuracion: 'Configuración',
                    material: 'Material'
            },
        }
    },
    computed: {
        ...mapGetters({
            cabecera: 'calendario/calendario/cabecera',
        }),
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'calendario.main',
            'calendario.ver.detalle'
        ]);
    },
    methods: {
        ...mapActions({
            Action_get_cabecera_calendario: 'calendario/calendario/Action_get_cabecera_calendario',
        }),
        abrirModalFiltrosEquipo(){
            this.$refs.modalFiltroEquipos.toggle()
        },
        verDetalleConfiguracion(id_configuracion){
            this.$router.push({ name: 'calendario.ver.detalle.configuracion', params: {id_configuracion:id_configuracion} })
        },
        verEtapasDia(){
            console.log('hacer peticion por dias');
        }
    },

}
</script>

<style lang="scss" scoped>
.calendario-ver-detalle{
    .list-day{
        background-color: white;
    }
    .p-tb-4px{
        padding: 4px 0px;
    }
    .month-day{
        border-radius: 4px;
        &-selected{
            border-radius: 4px;
            background:#d7d7d7;
            color: var(--color-general) !important;
            font-weight: 700;
        }
        &:hover{
            background:#d7d7d7;
        }
    }
}
</style>